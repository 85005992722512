/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import './src/styles/reset.scss';
import './src/styles/global.scss';
// import './src/styles/tailwind.css';


export const wrapPageElement = ({ element }) => (
	<AnimatePresence initial={false} exitBeforeEnter>
		{element}
	</AnimatePresence>
);

export const shouldUpdateScroll = () => false